<div class="h-full w-full" id="map" #mapContainer></div>
<p-button id="encInfoPanelDummyButton" (click)="encInfoPanel.show($event)"></p-button>
<p-overlayPanel
  #encInfoPanel
  [appendTo]="mapContainer"
  class="enc-info-panel"
  dismissable="false"
  showCloseIcon="true"
  (onHide)="onHideEncInfos()"
  (onShow)="onShowEncInfos()">
  <p-accordion [multiple]="false" (activeIndexChange)="onEncInfoChanged($event)" *ngIf="encInfos && encInfos.length">
    <p-accordionTab *ngFor="let encInfo of encInfos" header="{{ encInfo.name }} ({{ encInfo.id }})">
      <div class="side-by-side separator" *ngFor="let property of encInfo.properties">
        <div>{{ property.key }}:</div>
        <div>{{ property.value }}</div>
      </div>
    </p-accordionTab>
  </p-accordion>
  <div *ngIf="(!encInfos || !encInfos.length) && ! encError">No data available for this coordinate</div>
  <div *ngIf="encError">{{encError}}</div>
</p-overlayPanel>
