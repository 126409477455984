import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2SlideDownModule } from "ng2-slide-down";
import { HighchartsChartModule } from "highcharts-angular";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { ButtonModule } from "primeng/button";
import { MessagesModule } from "primeng/messages";
import { TabMenuModule } from "primeng/tabmenu";
import { ToastModule } from "primeng/toast";

import { AppRouting } from "./app.routes";
import { AppComponent } from "./app.component";
import { CoreModule } from "@app/core/core.module";

import { LoginComponent } from "./components/login/login.component";
import { InitiatePasswordResetComponent } from "./components/password/initiate-password-reset/initiate-password-reset.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";

import { NavMainComponent } from "./components/nav-main/nav-main.component";
import { NavVesselsComponent } from "./components/nav-vessels/nav-vessels.component";
import { NavAccountComponent } from "./components/nav-account/nav-account.component";

import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { MapOlComponent } from "./components/map/map-ol.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ChartComponent } from "./components/chart/chart.component";
import { ChartXYComponent } from "./components/chart/chart-x-y/chart-x-y.component";
import { ChartYTComponent } from "./components/chart/chart-y-t/chart-y-t.component";
import { EventsComponent } from "./components/events/events.component";
import { FleetReportsComponent } from "./components/reports/fleet-reports/fleet-reports.component";
import { VesselReportsComponent } from "./components/reports/vessel-reports/vessel-reports.component";
import { ReportsPanelComponent } from "./components/reports/reports-panel/reports-panel.component";

import { AuthGuard } from "./guards/auth/auth.guard";
import { LoginGuard } from "./guards/login/login.guard";
import { AuthService } from "./services/auth/auth.service";
import { ApiService } from "./services/api/api.service";
import { ChartsService } from "@app/services/charts/charts.service";
import { MockService } from "./services/api/mock.service";
import { UserService } from "./services/user/user.service";
import { ChartRangeService } from "@app/services/ranges/chart-range.service";
import { TrackRangeService } from "@app/services/ranges/track-range.service";
import { VesselGroupsService } from "./services/vessel-groups/vessel-groups.service";
import { VesselReportsService } from "./services/reports/vessel-reports.service";
import { RequestCacheService } from "@app/services/cache/request-cache.service";
import { CachingInterceptorService } from "@app/services/cache/caching-interceptor.service";
import { HullConditionService } from "@app/services/hull-condition/hull-condition.service";
import { FilesService } from "@app/services/hull-condition/files.service";

import { UtcStringPipe } from "app/pipes/utcString";
import { LocalStringPipe } from "app/pipes/localString";

import { NgClickOutsideDirective } from "ng-click-outside2";

import { RouterLinkActiveOptionsStubDirective, RouterLinkStubDirective, RouterOutletStubComponent } from "../testing/router-stubs";
import { PasswordModule } from "primeng/password";
import { ChangePasswordComponent } from "@app/components/password/change-password/change-password.component";
import { DividerModule } from "primeng/divider";
import { PasswordFormComponent } from "@app/components/password/password-form/password-form.component";
import { DialogModule } from "primeng/dialog";
import { PasswordResetComponent } from "@app/components/password/password-reset/password-reset.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HullConditionComponent } from "./components/hull-condition/hull-condition.component";
import { HullConditionRemarksComponent } from "./components/hull-condition/hull-condition-remarks/hull-condition-remarks.component";
import { MessageService } from "primeng/api";
import { HullConditionReportComponent } from "./components/hull-condition/hull-condition-report/hull-condition-report.component";
import { HullConditionTracksComponent } from "./components/hull-condition/hull-condition-tracks/hull-condition-tracks.component";
import { HullConditionFilesComponent } from "./components/hull-condition/hull-condition-files/hull-condition-files.component";
import { MapComponent } from "@app/components/map/map.component";
import { MapAisComponent } from "./components/map/map-ais.component";
import { MapWindyComponent } from "@app/components/map/map-windy.component";
import { WindyService } from "@app/services/map/windy.service";
import { MenubarModule } from "primeng/menubar";
import { MenuModule } from "primeng/menu";
import { PanelMenuModule } from "primeng/panelmenu";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { CardModule } from "primeng/card";
import { InputTextModule } from "primeng/inputtext";
import { CheckboxModule } from "primeng/checkbox";
import { PanelModule } from "primeng/panel";
import { ToggleButtonModule } from "primeng/togglebutton";
import { RippleModule } from "primeng/ripple";
import { DropdownModule } from "primeng/dropdown";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { AccordionModule } from "primeng/accordion";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InitiatePasswordResetComponent,
    DatePickerComponent,
    DashboardComponent,
    ChartComponent,
    ChartXYComponent,
    ChartYTComponent,
    EventsComponent,
    ChangePasswordComponent,
    PasswordResetComponent,
    PasswordFormComponent,
    FleetReportsComponent,
    VesselReportsComponent,
    ReportsPanelComponent,
    NotFoundComponent,
    MapComponent,
    MapAisComponent,
    MapOlComponent,
    MapWindyComponent,
    NavMainComponent,
    NavVesselsComponent,
    NavAccountComponent,

    RouterLinkStubDirective,
    RouterLinkActiveOptionsStubDirective,
    RouterOutletStubComponent,

    UtcStringPipe,
    LocalStringPipe,
    FooterComponent,
    HullConditionComponent,
    HullConditionRemarksComponent,
    HullConditionReportComponent,
    HullConditionTracksComponent,
    HullConditionFilesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRouting,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    HttpClientModule,
    NgClickOutsideDirective,
    Ng2SlideDownModule,
    NgxDaterangepickerMd.forRoot(),
    TableModule,
    MultiSelectModule,
    ButtonModule,
    MessagesModule,
    TabMenuModule,
    ToastModule,
    PasswordModule,
    DividerModule,
    DialogModule,
    MenubarModule,
    MenuModule,
    PanelMenuModule,
    ScrollPanelModule,
    CardModule,
    InputTextModule,
    CheckboxModule,
    PanelModule,
    ToggleButtonModule,
    RippleModule,
    DropdownModule,
    OverlayPanelModule,
    AccordionModule,
  ],
  providers: [
    DatePipe,
    MessageService,
    LoginGuard,
    AuthGuard,
    AuthService,
    ApiService,
    ChartsService,
    MockService,
    UserService,
    ChartRangeService,
    TrackRangeService,
    VesselGroupsService,
    VesselReportsService,
    HullConditionService,
    FilesService,
    RequestCacheService,
    WindyService,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
