export const environment = {
  production: true,
  api: {
    url: '/api',
    tokenEndpoint: '/oauth/token',
    clientId: 'webfrontend',
    clientSecret: ''
  },
  version: '2.21.0'
};
