import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as Highcharts from "highcharts";
import { SVGRendererSymbolPlus } from "@app/components/hull-condition/hull-condition-tracks/series-builder-config";
import { SeriesBuilderService } from "@app/components/hull-condition/hull-condition-tracks/series-builder.service";
import HC_more from "highcharts/highcharts-more";
import MapModule from "highcharts/modules/map";
import * as moment from "moment";

@Component({
  selector: "app-hull-condition-tracks",
  templateUrl: "./hull-condition-tracks.component.html",
  styleUrls: ["./hull-condition-tracks.component.scss"],
  providers: [SeriesBuilderService],
})
export class HullConditionTracksComponent implements OnChanges {
  static readonly fuelConsumptionTracks = ["FCREF", "FCREF BASE", "FCREF LATEST", "FCREF LIMIT", "FCREF WARN"];
  static readonly eventsTrack = ["HullEvent"];
  static readonly timeAtRestTrack = ["HC_TimeAtRest"];
  static readonly timeAtRestSeaWaterTempTrack = ["HC_TimeAtRestSeaWaterTemp"];

  static readonly longTrack = ["HC_Longitude"];
  static readonly latTrack = ["HC_Latitude"];
  static readonly tempTrack = ["HC_DailySeaWaterTemp"];

  @Input() hullConditionTracks: any | undefined = undefined;

  Highcharts = Highcharts;
  fuelConsumptionTrendsOptions: any = {};
  eventsOptions: any = {};
  bubbleOptions: any = {};
  mapOptions: any = {};

  private chartConfig = {
    chart: {
      type: "line",
      zoomType: "x",
      style: {
        fontFamily: "Heebo",
      },
    },
    legend: {
      itemStyle: {
        fontWeight: "bold",
      },
      itemHiddenStyle: {
        color: "#cccccc",
        textDecoration: "none",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      borderWidth: 1,
    },
  };

  private colorAxis = {
    colorAxis: {
      min: 7.5,
      max: 32.5,
      stops: [
        [0.0, "#4C75A1"],
        [0.2, "#00927A"],
        [0.4, "#70AC2E"],
        [0.6, "#FABA00"],
        [0.8, "#D40A18"],
        [1, "#6A050C"],
      ],
      labels: {
        formatter: function () {
          return this.value + " °C";
        },
        style: {
          color: "#666666",
        },
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [5, 10, 15, 20, 25, 30, 35],
      width: 700,
    },
  };

  private bubbleConfig = {
    chart: {
      type: "bubble",
      zooming: {
        type: "xy",
      },
    },
    tooltip: {
      borderWidth: 1,
      formatter: function () {
        return `<b> Date:</b> ${Highcharts.dateFormat("%A %e %b %Y", this.point.x)}<br><b>Water Temp.:</b> ${this.point.y}°C<br><b>Days:</b> ${Math.sqrt(this.point.z)} `;
      },
    },
  };

  private mapConfig = {
    chart: {
      height: 500,
      type: "map",
      events: {
        load: function () {
          const chart = this;
          const updateBackgroundImage = () => {
            const plotBackground = chart.plotBackground;

            if (plotBackground) {
              const x = plotBackground.attr("x");
              const y = plotBackground.attr("y");
              const width = plotBackground.attr("width");
              const height = plotBackground.attr("height");
              const offsetX = width * 0.03;
              const compressionY = height * 0.03;

              if (!chart.customImage) {
                chart.customImage = chart.renderer
                  .image("/assets/img/world-map.svg", x + offsetX, y, width, height - compressionY)
                  .attr({
                    zIndex: 0,
                  })
                  .add();
              } else {
                chart.customImage.attr({
                  x: x + offsetX,
                  y: y + compressionY,
                  width: width,
                  height: height - compressionY,
                });
              }
            }
          };

          updateBackgroundImage();

          Highcharts.addEvent(chart, "redraw", updateBackgroundImage);
        },
      },
      zooming: {
        type: "",
      },
      style: {
        fontFamily: "Heebo",
      },
    },

    mapNavigation: {
      enabled: false,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    tooltip: {
      borderWidth: 1,
      formatter: function () {
        return `<b> Long:</b> ${this.point.x}°<br><b>Lat.:</b> ${this.point.y}°<br><b>Temp:</b> ${this.point.z}°C`;
      },
    },
  };

  private BubbleYAxisConfig = {
    yAxis: {
      labels: {
        align: "right",
        x: -8,
        style: {
          color: "#666666",
        },
      },
      title: {
        text: "Water Temperature [°C]",
        style: {
          "font-weight": "bold",
          fill: "#000000",
          color: "#000000",
        },
      },
      lineColor: "#ccd6eb",
      lineWidth: 1,
      max: 35,
      min: 0,
      endOnTick: false,
      startOnTick: false,
      tickPositions: [0, 5, 10, 15, 20, 25, 30, 35],
    },
  };

  private MapYAxisConfig = {
    yAxis: {
      gridLineWidth: 1,
      labels: {
        align: "right",
        x: -8,
      },
      title: {
        text: "Lat [°]",
        style: {
          "font-weight": "bold",
          fill: "#000000",
          color: "#000000",
        },
      },
      lineColor: "#ccd6eb",
      lineWidth: 1,
      max: 90,
      min: -90,
      endOnTick: false,
      startOnTick: false,
      tickPositions: [-90, -60, -30, 0, 30, 60, 90],
    },
  };

  private FuelConsumptionYAxisConfig = {
    yAxis: {
      title: {
        text: "FCRef [t/d]",
        style: {
          "font-weight": "bold",
          fill: "#000000",
          color: "#000000",
        },
      },
      labels: {
        align: "right",
        x: -8,
        style: {
          color: "#666666",
        },
      },
      lineColor: "#ccd6eb",
      lineWidth: 1,
      max: undefined,
    },
  };

  private eventYAxisConfig = {
    yAxis: {
      title: {
        text: "Events",
        style: {
          "font-weight": "bold",
          fill: "#000000",
          color: "#000000",
        },
      },
      labels: {
        style: {
          color: "#ffffff",
        },
      },
      lineColor: "#ccd6eb",
      lineWidth: 1,
      max: undefined,
    },
  };

  private xAxisConfig = {
    xAxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#666666",
        },
        formatter: function () {
          const date = new Date(this.value);
          return `${date.toLocaleString("en-GB", { month: "short" })}. '${date.getFullYear().toString().slice(-2)}`;
        },
      },
      lineColor: "#ccd6eb",
      type: "datetime",
      gridLineWidth: 1,
      tickColor: "#ccd6eb",
      min: this.getStartOfDiagram(),
      max: this.getEndOfDiagram(),
      tickPositions: [
        this.getStartOfDiagram(),
        moment(this.getStartOfDiagram()).add(6, "months").valueOf(),
        moment(this.getStartOfDiagram()).add(1, "year").valueOf(),
        moment(this.getStartOfDiagram()).add(1, "year").add(6, "months").valueOf(),
        moment(this.getStartOfDiagram()).add(2, "years").valueOf(),
        moment(this.getStartOfDiagram()).add(2, "years").add(6, "months").valueOf(),
        moment(this.getStartOfDiagram()).add(3, "years").valueOf(),
        moment(this.getStartOfDiagram()).add(3, "years").add(6, "months").valueOf(),
        moment(this.getStartOfDiagram()).add(4, "years").valueOf(),
        moment(this.getStartOfDiagram()).add(4, "years").add(6, "months").valueOf(),
        moment(this.getStartOfDiagram()).add(5, "years").valueOf(),
        moment(this.getStartOfDiagram()).add(5, "years").add(6, "months").valueOf(),
      ],
    },
  };

  private MapxAxisConfig = {
    xAxis: {
      title: {
        text: "Long [°]",
        style: {
          "font-weight": "bold",
          fill: "#000000",
          color: "#000000",
        },
      },
      labels: {
        style: {
          color: "#666666",
        },
      },
      lineColor: "#ccd6eb",
      gridLineWidth: 1,
      tickColor: "#ccd6eb",
      min: -180,
      max: 180,
      tickPositions: [-180, -150, -120, -90, -60, -30, 0, 30, 60, 90, 120, 150, 180],
    },
  };

  constructor(private seriesBuilderService: SeriesBuilderService) {
    Highcharts.SVGRenderer.prototype.symbols.plus = SVGRendererSymbolPlus;
    HC_more(Highcharts);
    MapModule(Highcharts);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["hullConditionTracks"]) {
      this.handleHullConditionTracksChange(changes["hullConditionTracks"].currentValue);
    }
  }

  private handleHullConditionTracksChange(tracks: any) {
    const fuelConsumptionTracks = this.filterTracks(tracks, HullConditionTracksComponent.fuelConsumptionTracks);
    const events = this.filterTracks(tracks, HullConditionTracksComponent.eventsTrack);
    const yTrack = this.filterTracks(tracks, HullConditionTracksComponent.timeAtRestSeaWaterTempTrack);
    const zTrack = this.filterTracks(tracks, HullConditionTracksComponent.timeAtRestTrack);
    const long = this.filterTracks(tracks, HullConditionTracksComponent.longTrack);
    const lat = this.filterTracks(tracks, HullConditionTracksComponent.latTrack);
    const temp = this.filterTracks(tracks, HullConditionTracksComponent.tempTrack);

    const bubbles = this.mergeBubbleData(yTrack, zTrack);
    const mapTracks = this.mergeMapData(long, lat, temp);

    this.fuelConsumptionTrendsOptions = fuelConsumptionTracks
      ? {
          ...this.chartConfig,
          ...this.FuelConsumptionYAxisConfig,
          ...this.xAxisConfig,
          yAxis: {
            ...this.FuelConsumptionYAxisConfig.yAxis,
            max: this.getYAxisMaxFromTracks(tracks),
          },
          ...this.getSeriesFromTracks(fuelConsumptionTracks),
        }
      : undefined;

    this.eventsOptions = events
      ? {
          ...this.chartConfig,
          chart: {
            ...this.chartConfig.chart,
            height: "200px",
          },
          ...this.eventYAxisConfig,
          ...this.xAxisConfig,
          ...this.getSeriesFromTracks(events),
        }
      : undefined;

    this.bubbleOptions = bubbles
      ? {
          ...this.chartConfig,
          ...this.bubbleConfig,
          ...this.colorAxis,
          ...this.BubbleYAxisConfig,
          ...this.xAxisConfig,
          plotOptions: {
            bubble: {
              marker: {
                fillOpacity: 1,
              },
              minSize: 1,
              maxSize: 100,
            },
          },
          series: [
            {
              type: "bubble",
              name: "",
              showInLegend: false,
              colorKey: "y",
              data: bubbles,
            },
          ],
        }
      : undefined;

    this.mapOptions = mapTracks
      ? {
          ...this.chartConfig,
          ...this.mapConfig,
          ...this.colorAxis,
          ...this.MapYAxisConfig,
          ...this.MapxAxisConfig,
          plotOptions: {
            bubble: {
              marker: {
                fillOpacity: 1,
              },
              minSize: 10,
              maxSize: 10,
            },
          },
          series: [
            {
              type: "bubble",
              name: "",
              colorKey: "z",
              data: mapTracks,
            },
          ],
        }
      : undefined;
  }

  private getStartOfDiagram(): number {
    return new Date("2020-01-01T00:00:00Z").getTime();
  }

  private getEndOfDiagram(): number {
    return new Date().getTime();
  }

  private filterTracks(tracks: Array<any>, names: string[]): any {
    if (tracks == undefined) {
      return tracks;
    }

    if (names === HullConditionTracksComponent.eventsTrack) {
      return tracks.filter((track) => {
        return HullConditionTracksComponent.eventsTrack.some((group) => track.group === group);
      });
    }

    return tracks.filter((track) => {
      return names.some((abbreviation) => track.abbreviation === abbreviation);
    });
  }

  private getSeriesFromTracks(tracks) {
    return {
      series: tracks.map((track) => {
        return this.seriesBuilderService.getSerieFromTrack(track);
      }),
    };
  }

  private getYAxisMaxFromTracks(tracks) {
    if (Array.isArray(tracks) === false) {
      return undefined;
    }

    const fcrRefBaseTrack = tracks.find((track) => {
      return track.abbreviation === "FCREF BASE";
    });
    if (!fcrRefBaseTrack) {
      return undefined;
    }

    const fcrRefBaseTrackLatestValue = fcrRefBaseTrack.values.slice(-1)[0].value;

    return Math.round(fcrRefBaseTrackLatestValue * 1.7);
  }

  private mergeBubbleData(yTrack: any, zTrack: any): any[] {
    if (!yTrack || !yTrack[0] || !yTrack[0].values) {
      return undefined;
    }
    if (!zTrack || !zTrack[0] || !zTrack[0].values) {
      return undefined;
    }
    const zMap = new Map<number, number>(zTrack[0].values.map((item: any) => [item.measuredAt_epoch_ms, item.value]));

    const mergedData = [];
    yTrack[0].values.forEach((yItem: any) => {
      const x = yItem?.measuredAt_epoch_ms;
      const y = yItem?.value;

      if (zMap.has(x)) {
        const z = zMap.get(x) ** 2;
        mergedData.push({ x, y, z });
      }
    });

    return mergedData;
  }

  private mergeMapData(xTrack: any, yTrack: any, zTrack: any): any[] {
    if (!xTrack || !xTrack[0] || !xTrack[0].values) {
      return undefined;
    }
    if (!yTrack || !yTrack[0] || !yTrack[0].values) {
      return undefined;
    }
    if (!zTrack || !zTrack[0] || !zTrack[0].values) {
      return undefined;
    }

    const map = new Map<number, { x: number; y: number; z: number }>(xTrack[0].values.map((item: any) => [item.measuredAt_epoch_ms, { x: item.value }]));

    yTrack[0].values.forEach((yItem: any) => {
      const key = yItem?.measuredAt_epoch_ms;
      if (map.has(key)) {
        let value = map.get(key);
        value.y = yItem?.value;
        map.set(key, value);
      }
    });
    zTrack[0].values.forEach((zItem: any) => {
      const key = zItem?.measuredAt_epoch_ms;
      if (map.has(key)) {
        let value = map.get(key);
        value.z = zItem?.value;
        map.set(key, value);
      }
    });

    const values = [];
    for (const value of map.values()) {
      if (value.x && value.y && value.z) {
        values.push(value);
      }
    }
    return values;
  }
}
