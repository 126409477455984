export const enum ProductType {
  CTS,
  DEMO,
  FOS,
  VPER,
  UNDEFINED,
}

export class Product {
  readonly name: ProductType;
  readonly dataLimitDays: number;

  private readonly vesselIdRangeStart: number;
  private readonly vesselIdRangeEnd: number;

  constructor(name: ProductType, rangeStart: number, rangeEnd: number, dataLimitDays: number) {
    this.name = name;
    this.vesselIdRangeStart = rangeStart;
    this.vesselIdRangeEnd = rangeEnd;
    this.dataLimitDays = dataLimitDays;
  }

  matches(vesselId: number): boolean {
    return this.vesselIdRangeStart <= vesselId && vesselId <= this.vesselIdRangeEnd;
  }
}

export class Products {
  private static readonly undefinedProduct = new Product(ProductType.UNDEFINED, -1, -1, 365);

  private static readonly products: Array<Product> = [
    new Product(ProductType.CTS, 1000000, 1050000, 30),
    new Product(ProductType.DEMO, 500, 999, 365),
    new Product(ProductType.FOS, 2000000, 2050000, 90),
    new Product(ProductType.VPER, 1, 400, 365),
  ];

  static getProduct(vesselId: number): Product {
    const product = this.products.find((product) => product.matches(vesselId));
    return product ? product : this.undefinedProduct;
  }
}
