import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";

export const enum TrailResolution {
  SixHours = 1000 * 60 * 60 * 6,
  OneHour = 1000 * 60 * 60,
  TenMinutes = 1000 * 60 * 10,
  FiveMinutes = 1000 * 60 * 5,
  All = 1000
}

export class Trail {
  private readonly resolution: TrailResolution;
  private readonly trailSource = new VectorSource();
  private readonly trailLayer: VectorLayer;
  private lastTime = Number.MAX_SAFE_INTEGER;
  private features: Feature<Point>[] = [];

  constructor(resolution: TrailResolution, minZoom: number, maxZoom: number) {
    this.resolution = resolution;
    this.trailLayer = new VectorLayer({
      minZoom: minZoom,
      maxZoom: maxZoom,
      source: this.trailSource,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      // @ts-ignore
      displayInLayerSwitcher: false
    });
  }

  getLayer(): VectorLayer {
    return this.trailLayer;
  }

  clear() {
    this.trailSource.clear(true);
    this.lastTime = Number.MAX_SAFE_INTEGER;
  }

  addFeature(position: any, feature: Feature<Point>) {
    if (this.lastTime >= position.measuredAt + this.resolution) {
      this.features.push(feature);
      this.lastTime = position.measuredAt;
    }
  }

  commit() {
    this.trailSource.addFeatures(this.features);
    this.features = [];
  }
}
